




































































import AppComponent from '@/mixins/ComponentMixin.vue';
import {Component, Prop} from 'vue-property-decorator';
import {IRequisition, IReviewRequisition, ITestResult} from '@/interfaces/requisitions';
import {dispatchGetCategories, dispatchUpdateRequisition} from '@/store/crud/actions';
import TestResultsTable from '@/components/tables/TestResultsTable.vue';
import {readUserProfile} from '@/store/main/getters';
import PatientData from '@/components/PatientData.vue';
import {INote} from '@/interfaces/notes';
import {dispatchAddNotification} from '@/store/main/actions';

@Component({
  components: {PatientData, TestResultsTable},
})
export default class PhysicianReview extends AppComponent {
  @Prop({type: Object}) public requisitionOnView: IRequisition;
  @Prop({type: Array}) public testResults: ITestResult[];
  @Prop({type: Array}) public notes: INote[];

  public panelIndex: number[] = [0, 1, 2];
  public confirmButtonDisabled = false;

  public get priority() {
    let priority = 0;
    // check if there is critical values
    if (this.testResults.some((result) => result.biomarkerCritical && result.resultCritical)) {
      // check if there is any priority 1 critical
      if (
        this.testResults.some(
          (result) =>
            result.biomarkerCritical && result.isOutOfRangePriority1 && result.isPriority1,
        )
      ) {
        priority = 1;
      } else {
        priority = 2;
      }
    } else {
      return false;
    }
    return priority;
  }

  /**
   * Approves the test result, this will set the reviewed flag to true
   * It needs at least one comment to be able to approve the test result
   */
  public async approve() {
    const overallNote = this.notes.find((note) => !note.category);
    if (!overallNote) {
      await dispatchAddNotification(this.$store, {
        message: 'Summary is required in order to approve the test result',
        color: 'warning',
      });
      return;
    }

    if (!this.requisitionOnView?.id) {
      this.toast('Unable to approve requisition', true);
      return;
    }

    const user = readUserProfile(this.$store);

    if (!user?.isPhysician || user?.isNursePracticioner || user?.isReviewer) {
      this.toast('You are not authorized to approve this test result', true);
      return;
    }

    const approvedResult = {} as IReviewRequisition;
    approvedResult.reviewingPhysicianId = (user?.id as string) || ('' as string);
    approvedResult.reviewed = true;

    this.confirmButtonDisabled = true;

    const result: boolean = await dispatchUpdateRequisition(this.$store, {
      requisitionId: this.requisitionOnView.id,
      requisition: approvedResult,
    });

    if (result) {
      this.toast('Test result approved!', false);
      this.$router.push('/main/physician/results');
    } else {
      this.toast('Error while approving result', true);
    }
    this.confirmButtonDisabled = false;
    if (this.requisitionOnView?.id) await this.trackTimes(this.requisitionOnView?.id, 'Complete');
  }

  public created() {
    dispatchGetCategories(this.$store);
  }
}
